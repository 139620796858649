import { ListItem, makeStyles } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import useAxios, { ServerRoutes, ShoppingServiceRoute } from 'api';
import { CircularProgress, CustomButton, InputFieldWithLabel, Snackbar } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import StyleItems from 'components/lists/StyleItems';
import DefaultThumbnail from 'assets/defaultThumbnail.png';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from 'routes';
import Colors from 'theme';
import { CreateStyleItemProps, CreateStyleProps, Toast, UniversityCatalogProps } from 'types';
import { GET, POST } from 'utils';
import { v4 } from 'uuid';

const useStyles = makeStyles(() => ({
  root: {
    background: Colors.plain_white,
    fontFamily: 'Wigrum',
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    overflowX: 'hidden',
  },
  styleWrapper: {
    marginLeft: '73px',
    width: '30%',
    borderRight: '1px solid beige',
  },
  roomItems: {
    margin: '5px 0px 26px',
    color: Colors.cyprus1,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  styleItemList: {
    maxHeight: '89.4vh',
    overflow: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
  },
  list: {
    margin: '10px 0px',
    maxHeight: '82.2vh',
    overflow: 'auto',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: Colors.cyprus1,
  },
  createStyleButton: {
    marginBottom: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'baseline',
  },
  contentWrapper: { marginLeft: '20px', width: '64%' },
  inputFieldWrapper: { width: '100%', marginBottom: '10px' },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '10px',
  },
  flex: {
    display: 'flex',
    transition: 'transform 0.4s',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  backIcon: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    color: Colors.cyprus1,
  },
  backText: {
    cursor: 'pointer',
    color: Colors.cyprus1,
    fontSize: '12px',
  },
  styleId: {
    cursor: 'pointer',
    color: Colors.cyprus1,
    fontSize: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  thumbnail: {
    width: '40px',
    height: '40px',
    marginRight: '20px',
  },
  searchItemInput: {
    marginBottom: '10px',
  },
  styleInfo: {
    fontWeight: 'bold',
  },
}));
const STYLE_INITIAL = { styleName: '', styleThumbnail: '', active: true, id: '', styleItems: [], styleCatalog: [] };

const CreateStyle = () => {
  const classes = useStyles();
  const [catalogs, setCatalogs] = useState<UniversityCatalogProps[]>([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState<UniversityCatalogProps[]>([]);
  const [searchItem, setSearchItem] = useState('');
  const [style, setStyle] = useState<CreateStyleProps>(STYLE_INITIAL);
  const [toast, setToast] = useState<Toast>();
  const [showToast, setShowToast] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const styleId = searchParams.get('styleId');
  const history = useHistory();

  const { callApi: loadStylesCatalogs, loading: styleCatalogLoading } = useAxios(
    ShoppingServiceRoute.getStyleCatalog,
    GET,
    false,
    '',
    {},
    'shopping',
  );

  const { callApi: createStyleApi, loading: saveStyleLoading } = useAxios(ServerRoutes.stylesApi, POST, false);

  const { callApi: getStyleByIdApi, loading: loadStyleItemCatalogsLoading } = useAxios(
    `${ServerRoutes.stylesApi}/${styleId}`,
    GET,
    false,
  );

  useEffect(() => {
    fetchCatalogs();
  }, []);

  const fetchCatalogs = async () => {
    await loadStylesCatalogs({}, (catalogsResp) => {
      if (catalogsResp.data) {
        setCatalogs(catalogsResp.data);
        setFilteredCatalogs(catalogsResp.data);
      }
    });
  };

  const handleSearch = (value: any) => {
    if (!value) setFilteredCatalogs(catalogs);
    else {
      const catalog = catalogs.filter((item) => item.itemName.toLowerCase().includes(value.toLowerCase()));
      setFilteredCatalogs(catalog);
    }
    setSearchItem(value);
  };

  const fetchStyleById = async () => {
    if (styleId) {
      await getStyleByIdApi({}, (styleResponse) => {
        if (styleResponse.data) {
          setStyle({ ...styleResponse.data });
        }
      });
    }
  };

  useEffect(() => {
    fetchStyleById();
  }, [styleId]);

  const addSelectedItem = (catalogItem: UniversityCatalogProps, catalogItemId: string) => {
    if (!catalogItemId) {
      setShowToast(true);
      setToast({
        severity: 'error',
        message: 'Please select variant!',
        autoHideDuration: 3000,
      });
      return;
    }
    let itemData: CreateStyleItemProps;
    if (catalogItem) {
      catalogItem?.catalogItems &&
        catalogItem?.catalogItems?.forEach((styleItem: any) => {
          if (catalogItemId === styleItem.catalogItemId) {
            itemData = {
              itemId: v4(),
              catalogId: styleItem.catalogId,
              variantId: styleItem.catalogItemId,
              assetUrl: styleItem?.url,
              deletable: true,
              moveable: true,
              parentItemId: styleItem?.catalogItemId,
              anchor: styleItem?.anchor,
              type: styleItem?.descriptorTags?.type || '',
              subType: styleItem?.descriptorTags?.subType || '',
              surfaceType: styleItem?.descriptorTags?.surfaceType || '',
              itemName: catalogItem?.itemName,
              active: catalogItem?.active,
              thumbnailURL: catalogItem.thumbnail,
            };
          }
        });
    }
    setStyle((prevList: CreateStyleProps) => ({
      ...prevList,
      styleItems: [...prevList.styleItems, { ...itemData }],
    }));
  };

  const createNewStyle = async (action: string) => {
    if (action === 'create') {
      delete style?.id;
    }
    delete style.styleCatalog;
    await createStyleApi(style, async (saveRoomResp) => {
      if (saveRoomResp.data.code == 0) {
        setStyle({ ...saveRoomResp.data[0] });
        setShowToast(true);
        setToast({
          severity: 'success',
          message: `${style.styleName} style is ${action}d successfully!!!`,
          autoHideDuration: 3000,
        });
        setTimeout(() => {
          history.push(Routes.STYLES_DASHBOARD);
        }, 3000);
      }
    });
  };

  const removeSelectedItem = (itemId: string) => {
    const styles = style?.styleItems.filter((item: CreateStyleItemProps) => item.itemId !== itemId);
    setStyle((prevList: CreateStyleProps) => ({
      ...prevList,
      styleItems: styles,
    }));
  };

  return (
    <div className={classes.root}>
      <Snackbar
        isOpen={showToast}
        setIsOpen={setShowToast}
        messageHeader={toast?.messageHeader}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
        autoHideDuration={toast?.autoHideDuration}
      />
      <CircularProgress isLoading={styleCatalogLoading || loadStyleItemCatalogsLoading || saveStyleLoading} />
      <div className={classes.styleWrapper}>
        <div className={classes.topContainer}>
          <div
            style={{
              display: 'flex',
              transition: ' transform 0.4s',
              alignItems: 'center',
              margin: ' 20px 0',
            }}
            onClick={() => history.push(Routes.STYLES_DASHBOARD)}
          >
            <ArrowBackIos className={classes.backIcon} />
            <div className={classes.backText}>Back to Styles</div>
          </div>
        </div>
        {styleId && (
          <div className={classes.styleId} title={styleId}>
            <span className={classes.styleInfo}>Style id : </span>
            {styleId}
          </div>
        )}
        <div className={classes.searchItemInput}>
          <InputFieldWithLabel
            value={searchItem}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search Items"
            width="95%"
          />
        </div>
        <div className={classes.styleItemList}>
          {filteredCatalogs
            .filter((catalog) => catalog.active)
            .map((catalog) => (
              <StyleItems key={catalog.catalogId} catalog={catalog} addStyleItems={addSelectedItem} />
            ))}
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div className={classes.inputFieldWrapper}>
            <InputFieldWithLabel
              placeholder="Name Of Style"
              value={style?.styleName}
              onChange={(e) => {
                setStyle((prevList: CreateStyleProps) => ({ ...prevList, styleName: e.target.value }));
              }}
              width="90%"
              height="48px"
            />
          </div>
          <div className={classes.inputFieldWrapper}>
            <InputFieldWithLabel
              placeholder="Thumbnail URL"
              value={style?.styleThumbnail}
              onChange={(e) => {
                setStyle((prevList: CreateStyleProps) => ({ ...prevList, styleThumbnail: e.target.value }));
              }}
              width="90%"
              height="48px"
            />
          </div>
          {!styleId ? (
            <div className={classes.createStyleButton}>
              <CustomButton
                enable={style?.styleName && style?.styleThumbnail && style?.styleItems?.length > 0 ? true : false}
                title="Create Style"
                borderRadius="6px"
                solid={false}
                width="150px"
                buttonClicked={() => createNewStyle('create')}
                textColor={Colors.cyprus}
                background={Colors.lightWhite}
                fontWeight="500"
              />
            </div>
          ) : (
            <div className={classes.createStyleButton}>
              <CustomButton
                enable={style?.styleName && style?.styleThumbnail && style?.styleItems?.length > 0 ? true : false}
                title="Update Style"
                borderRadius="6px"
                solid={false}
                width="150px"
                buttonClicked={() => createNewStyle('update')}
                textColor={Colors.cyprus}
                background={Colors.lightWhite}
                fontWeight="500"
              />
            </div>
          )}
        </div>
        {!styleCatalogLoading && !loadStyleItemCatalogsLoading && (
          <>
            {' '}
            <div className={classes.roomItems}>Selected Products</div>
            {style.styleItems?.length > 0 ? (
              <div className={classes.list}>
                {style.styleItems &&
                  style.styleItems.map((item: CreateStyleItemProps, index: number) => (
                    <ListItem className={classes.item} button key={index}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={item.thumbnailURL ? item.thumbnailURL : DefaultThumbnail}
                          height={50}
                          width={50}
                          style={{
                            marginRight: 20,
                          }}
                        />
                        {item.itemName || 'No Name Provided'}
                      </div>
                      <div onClick={() => removeSelectedItem(item.itemId)}>
                        <DeleteIcon />
                      </div>
                    </ListItem>
                  ))}
              </div>
            ) : (
              <h2 style={{ textAlign: 'center' }}>Please add products!</h2>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateStyle;
